<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right">
        <el-form :inline="true" :model="queryForm" style="">
          <tchtree ref="tchtree" v-model="queryForm.class_ids" @change="getList" @changeType="changeType" style="margin: 0 10px 10px 0;"></tchtree>
          <el-form-item label="" min-width="120">
            <el-input size="small" v-model="queryForm.keyword" placeholder="请输入关键字"></el-input>
          </el-form-item>


          <el-form-item>
            <el-button size="small" type="primary" icon="el-icon-search" @click="getList">搜索</el-button>


            <!--                        <el-upload style="display: inline-block; margin-left: 10px;" action="" :multiple="true"
                                        :http-request="uploadOss" :on-success="handleFileUpload" :on-progress="handleProcess"
                                        :show-file-list="false" name="image">
                                        <el-button size="small" type="primary" icon="el-icon-upload">上传</el-button>
                                    </el-upload>-->
            <el-button size="small" type="primary" icon="el-icon-download" plain @click="downloadFile">下载</el-button>
          </el-form-item>
        </el-form>

      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>数据上报</el-breadcrumb-item>
          <el-breadcrumb-item>{{ year_month + '月' }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
      <div style="height:4px">
        <div id="processbox" style="height:4px;border-radius: 2px; background-color: green; width: 0px;"></div>
      </div>
      <el-card>
        <el-table v-tableFit stripe :data="DataList"
                  style="width: 100%;margin-bottom: 20px; margin-top: 5px; color: #000;"
                  border size="mini">
          <el-table-column type="selection" width="55">
          </el-table-column>
          <el-table-column prop="cover" label="类型" width="60">
            <template slot-scope="scope">
              <div style="text-align:center">

                <img v-if="scope.row.ftype=='file'" :src="'./img/icon/'+scope.row.filetype+'.png?'"
                     class="typeicon"/>
                <img v-if="scope.row.ftype=='folder'" :src="'./img/icon/folder.png'" class="typeicon"/>


              </div>

            </template>
          </el-table-column>
          <el-table-column prop="class_name" label="班级名称" min-width="120"></el-table-column>
          <el-table-column prop="filename" label="文件名称" min-width="230">
            <template slot-scope="scope">
              <div class="filenameitem">
                <span @click.stop="viewInfo(scope.row)"> {{ scope.row.filename }}</span>
                <div class="fileicon">
                  <i class="el-icon-download" title="下载" @click.stop="openFile(scope.row)"></i>
                  <i class="el-icon-delete" title="删除"
                     @click.stop="deleteFile(scope.row)" style="margin-left:10px"></i>
                </div>
              </div>

            </template>
          </el-table-column>

          <el-table-column prop="id" label="上传日期" width="180">
            <template slot-scope="scope">
              {{ scope.row.cdate }}

            </template>
          </el-table-column>

          <el-table-column prop="filename" label="文件大小" width="120">
            <template slot-scope="scope">
              {{ getSize(scope.row.filesize) }}

            </template>
          </el-table-column>
          <el-table-column prop="filename" label="上传用户" width="120">
            <template slot-scope="scope">
              <span
                style="vertical-align:middle;line-height: 32px; margin-left: 5px; color: #888;">{{ scope.row.username || '系统生成' }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="handlePageChange" @size-change="handleSizeChange"
                       :current-page="Page.current_page" :page-size="Page.per_page" :page-sizes="[10, 20, 50, 100]"
                       layout="total, prev, pager, next,sizes, jumper" :total="Page.count">
        </el-pagination>

      </el-card>
    </div>


    <el-dialog :visible.sync="dialogshow" :close-on-click-modal="false" :modal-append-to-body="false"
               :destroy-on-close="true" @close="dialogshow = false" width="800px" custom-class="cus_dialog">
      <div slot="title" class="flexBetween">
        <span style="color: #ffffff;">上传文件</span>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogshow = false">取消</el-button>
        <el-button type="primary" @click="saveForm">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tchtree from "@/views/com/tchTree.vue";

export default {
  components: {tchtree},
  data() {
    return {
      queryForm: {
        keyword: "",
      },
      DataList: [],
      Page: {},
      dialogshow: false,
      year_month: "",
      deptType: 'xx',
    }
  },
  mounted() {
    this.year_month = this.$route.params.month
    this.getList()
  },
  methods: {
    getSize(size) {
      if (size) {
        if (size < 1024) {
          return size + "B"
        } else if (size >= 1024 && size < 1024 * 1024) {
          return parseInt(size / (1024) * 10) / 10 + "KB"
        } else if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
          return parseInt(size / (1024 * 1024) * 10) / 10 + "MB"
        } else if (size >= 1024 * 1024 * 1024 && size < 1024 * 1024 * 1024 * 1024) {
          return parseInt(size / (1024 * 1024 * 1024) * 10) / 10 + "GB"
        }
      } else {
        return "-"
      }
    },
    getList() {
      let arr = this.year_month ? this.year_month.split('-') : []
      this.$http.post("/api/month_report_list", {
        page: this.Page.current_page,
        pagesize: this.Page.per_page,
        keyword: this.queryForm.keyword,
        report_year: arr.length ? arr[0] : '',
        report_month: arr.length ? arr[1] : '',
        class_ids: this.queryForm.class_ids,
      }).then(res => {
        this.DataList = res.data.data
        this.Page = res.data.page
      })
    },
    openFile(e) {
      if (e.url) {
        //window.open(e.url, "_blank")
        this.$http.get(e.url, {
          responseType: 'arraybuffer'
        }).then(r => {
          var blob = new Blob([r.data], { type: 'application/octet-stream;charset=UTF-8' })
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.style.display = 'none'
          downloadElement.href = href
          downloadElement.download = decodeURIComponent(e.filename) // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href)
        })
      }
    },
    deleteFile(e) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http.post("/api/month_report_delete", {
          id: e.id
        }).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.getList()
        })

      }).catch(() => {

      });
    },
    saveForm() {
      if (this.sendToAll) {
        this.formData.target_teachers = "all";
        this.formData.target_classes = "all";
      } else {
        //发送对象
        if (this.allStudent) {
          this.formData.target_classes = "all"
        }

        if (this.allTeacher) {
          this.formData.target_teachers = "all"
        }
      }
      let item = JSON.parse(JSON.stringify(this.formData));
      if (!item.files.length) {
        return this.$message.error('请上传文件')
      }
      let saveFile = idx => {
        if (idx < item.files.length) {
          let file = item.files[idx]
          let formData = {
            pid: this.pid,
            ftype: "file",
            filetype: file.filetype,
            filename: file.fileName,
            filesize: file.size,
            url: file.src,
            is_share: 1,
            target_classes: item.target_classes,
            target_teachers: item.target_teachers,
          }
          this.$http.post('/api/t_sch_filesstore_edit', formData).then(res => {
            saveFile(idx + 1)
          });
        } else {
          this.dialogshow = false
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.getList()
        }
      }
      saveFile(0)
    },
    changeType(val) {
      this.deptType = val
    },
    handlePageChange(e) {
      this.Page.current_page = e
      this.getList()
    },
    handleSizeChange(e) {
      this.Page.per_page = e
      this.getList()
    },
    downloadFile() {
      if (!this.queryForm.class_ids) {
        this.deptType = 'xx'
      }
      let arr = this.year_month ? this.year_month.split('-') : []
      this.$http.post('/api/monthReportDownload', {
        report_year: arr.length ? arr[0] : '',
        report_month: arr.length ? arr[1] : '',
        class_ids: this.queryForm.class_ids,
        dept_type: this.deptType,
      }).then(res => {
        window.open(res.data.url,'_blank')
      })
    },
  },
  beforeRouteUpdate(to,from,next) {
    this.queryForm.keyword = ""
    this.year_month = to.params.month
    this.$refs.tchtree.clearEmpty()
    next()
  }

};
</script>

<style scoped>
.filerow {
  padding: 10px;
  height: 30px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  line-height: 30px;
  display: flex;
  color: #333;
}

.filerow:hover {
  background-color: aliceblue;
}


.filename {
  width: 30%;
  padding-left: 10px;
}

.filedate {
  width: 120px;
  font-size: 12px;
}

.headimg {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  vertical-align: middle;
}

.typeicon {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  vertical-align: middle;
}

.filenameitem {
  position: relative;
}

.fileicon {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  color: #409EFF;
  display: block;
}

.filenameitem:hover .fileicon {
  display: block;
}

.filenameitem:hover {
  color: #409EFF;
  cursor: pointer;
}

.nextpath {
  font-size: 14px;
  color: #409EFF;
  cursor: pointer;
}
</style>